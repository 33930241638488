import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'

const useTimeline = (startDate?: Date, endDate?: Date): string => {
    const { locale } = useAppSelector((state) => state.settings)

    return useMemo(() => {
        if (!startDate || !endDate) {
            return ''
        }

        const startDateStr = new Date(startDate).toLocaleDateString(locale, {
            month: 'short',
            day: '2-digit'
        })

        const endDateStr = new Date(endDate).toLocaleDateString(locale, {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        })

        return `${startDateStr} - ${endDateStr}`
    }, [startDate, endDate, locale])
}

export default useTimeline
